<template>
  <div>
    <partner-dashboard :userId="userId" roleView="admin" />
  </div>
</template>

<script>
import PartnerDashboard from '@/views/apps/partners/dashboard/PartnerDashboard.vue'

export default {
  components: {
    PartnerDashboard,
  },
  data() {
    return {
      userId: null,
    }
  },
  async created() {
    const { query, params } = this.$route
    this.userId = params?.id
  },
  methods: {},
}
</script>

<style lang="scss" scoped></style>
