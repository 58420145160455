<template>
  <div>
    <!-- <h1>partner dashboard {{ userId }}</h1> -->
    <b-tabs
      v-if="userId"
      v-model="tabActive"
      lazy
      pills
      :vertical="$store.getters['app/currentBreakPoint'] === 'xs'"
      content-class="col-12 px-sm-0"
      nav-wrapper-class="col-12 px-sm-0"
      nav-class="nav-left mb-2"
      @activate-tab="onActiveTab"
    >
      <b-tab v-for="tab in cTabs" :key="tab.key" :title="tab.name" lazy>
        <component
          :is="tab.component"
          :ref="tab.ref"
          :user-id="userId"
          :date-select-options="dateSelectOptions"
          :date-selected="dateSelected"
          :page-loading="loading"
          :role-view="roleView"
          @changeDateSelect="onChangeDateSelect"
          @updatePageLoading="updatePageLoading"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  props: {
    userId: {
      type: String,
      default: '',
    },
    roleView: {
      type: String,
      default: 'partner',
    },
  },
  data() {
    return {
      dateSelectOptions: [],
      dateSelected: null,
      tabActive: 0,
      loading: false,
    }
  },
  computed: {
    cTabs() {
      const tabs = [
        {
          key: 'general',
          name: 'ข้อมูลทั่วไป',
          component: () => import('./components/TabGeneralDetails.vue'),
          ref: 'refTabGeneral',
        },
        {
          key: 'sales',
          name: 'รายได้',
          component: () => import('./components/TabSalesDetails.vue'),
          ref: 'refTabSales',
        },
      ]

      if (this.roleView === 'admin') {
        tabs.push({
          key: 'account',
          name: 'ข้อมูลบัญชี',
          component: () => import('./components/TabAccountDetails.vue'),
          ref: 'refTabAccount',
        })
      }

      return tabs
    },
  },
  async created() {
    const { query } = this.$route
    if (query.tab) {
      const idx = this.cTabs.findIndex(v => v.key === query.tab)
      if (idx >= 0) {
        this.tabActive = idx
      } else {
        this.$router.replace({ ...this.$route, query: { tab: this.cTabs[0].key } })
      }
    }
    this.loading = true
    await this.getDateOptionsByUserId(this.userId)
    this.loading = false
  },
  methods: {
    onActiveTab(newTabIndex) {
      const tab = this.cTabs[newTabIndex].key

      this.$router.replace({ ...this.$route, query: { tab } })
    },
    async getDateOptionsByUserId(id) {
      if (!id) return
      const resp = await this.api.getV2(`api/dashboard/fetch-date?userId=${id}`).catch(() => null)
      // console.log('[RESP] getDateOptionsByUserId => ', resp)

      if (resp && resp.code === 200 && resp.data.length > 0) {
        const { data } = resp
        this.dateSelectOptions = [...data]
        // eslint-disable-next-line prefer-destructuring
        this.dateSelected = data[0]
      } else {
        const defaultMonthYear = this.$date().format('YYYY-MM-01')
        this.dateSelectOptions = [defaultMonthYear]
        this.dateSelected = defaultMonthYear
      }
    },
    onChangeDateSelect(date) {
      this.dateSelected = date
    },
    updatePageLoading(val) {
      this.loading = val
    },
  },
}
</script>

<style lang="scss" scoped></style>
